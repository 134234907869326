<!-- 双光 -->
<template>
  <div class="cpt-MMC_Gimbal_ZT30N" :style="containerStyle">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">红外热成像</div>
      </div>
      <div @click="close" class="close">关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
            <!-- <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.ptz_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">视频指点:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_dj_mode" v-model="dj_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="item-group jcsb ml100 w100">
          <div @click="handle_take_photo" class="item-box">
            <el-tooltip
              class="item"
              effect="dark"
              content="拍照"
              placement="top"
            >
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt />
              </div>
            </el-tooltip>
          </div>
          <div @click="handle_record" class="item-box">
            <el-tooltip
              class="item"
              effect="dark"
              content="录像"
              placement="top"
            >
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img
                  v-if="!record"
                  src="~@/assets/images/mount/record.png"
                  alt
                />
                <img v-else src="~@/assets/images/mount/stop.png" alt />
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">画 中 画</div>
          <div class="input-box">
            <el-select
              @change="handle_change_image_mode"
              v-model="image_mode"
              size="mini"
              placeholder="请选择画中画"
            >
              <el-option label="主高清" :value="0"></el-option>
              <el-option label="主红外" :value="1"></el-option>
              <el-option label="单高清" :value="2"></el-option>
              <el-option label="单红外" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            伪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;彩
          </div>
          <div class="input-box">
            <el-select
              @change="handle_change_thermal_mode"
              v-model="thermal_mode"
              size="mini"
              placeholder="请选择彩伪"
            >
              <el-option label="白热" :value="0"></el-option>
              <el-option label="黑热" :value="1"></el-option>
              <el-option label="彩虹" :value="2"></el-option>
              <el-option label="熔岩" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子变倍</div>
          <div class="input-box">
            <el-select
              @change="handle_change_thermal_zoom"
              v-model="thermal_zoom"
              size="mini"
              placeholder="请选择电子变倍"
            >
              <el-option label="1倍" :value="1"></el-option>
              <el-option label="2倍" :value="2"></el-option>
              <el-option label="4倍" :value="4"></el-option>
              <el-option label="6倍" :value="6"></el-option>
              <el-option label="8倍" :value="7"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div
              class="mr10 cur"
              @mousedown="handle_zoom_reduce()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-shanchu3"></span>
            </div>
            <!-- <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button> -->

            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              @change="stopChange"
              @input="onChangezoom"
            ></el-slider>
            <div
              class="ml10 cur"
              @mousedown="handle_zoom_plus()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-tianjia1"></span>
            </div>

            <!-- <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button>
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              style="width: 180px"
              @change="stopChange"
              @input="onChangezoom"
            ></el-slider>
            <el-button
              @click="handle_zoom(1)"
              size="mini"
              icon="el-icon-plus"
              circle
            ></el-button> -->
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="rocker" ref="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="zuoUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="youUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_ZT30N } from "../utils";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    selected_mount: {
      type: Object,
      default: () => ({}),
    },
    moveType: {
      type: String,
      default: () => "",
    },
    keyFlag: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      holderModelDomV2: 1,
      timer: null,
      value: 25,
      value2: 25,
      control_speed: 1,
      ptz_mode: 1,
      image_mode: 0,
      thermal_mode: 0,
      thermal_zoom: 1,
      record: false,
      radio: 1,
      dj_mode: 0,
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
      },
    };
  },
  computed: {
    stream() {
      return this.$store.state.fckernel.stream;
    },
  },
  methods: {
    close() {
      if (this.record) {
        this.$message.info("请结束录像后再关闭！");
      } else {
        this.$emit("close");
      }
    },
    handle_take_photo() {
      // let { position } = this;
      // let buffer = MMC_Gimbal_Z30Pro.take_photo(position || {});
      if (!this.keyFlag) return this.$message.error("拍照失败，板载通信异常！");
      let streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
        streamData.data.messageID = 1016;
      } else {
        streamData.data.messageID = 1007;
      }
      this.$emit("take_photo", streamData);
    },
    handle_record() {
      if (!this.keyFlag) return this.$message.error("录像失败，板载通信异常！");
      this.record = !this.record;
      let streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
        streamData.data.messageID = 1017;
        streamData.data.data.status = this.record;
      } else {
        streamData.data.messageID = 1006;
        streamData.data.data.recordControl = this.record;
      }
      this.$emit("record", streamData);

      // let buffer = MMC_Gimbal_Z30Pro.record(this.record);
      // this.commit_directive(buffer);
    },
    handle_change_dj_mode(id) {
      this.$store.commit("device/SET_MOVE_DATA", {
        ...this.device,
        mountStatus: id,
        mountId: this.selected_mount.mountId,
        moveType: this.moveType,
        name: this.selected_mount.name,
      });
    },
    onChangezoom(e) {
      console.log(e, "e");
      if (e < 25) {
        let arr = [165, 4, 3, 0, 4];
        this.commit_directive(arr);
        // this.handle_zoom(0);
      }
      if (e > 25) {
        let arr = [165, 4, 3, 2, 102];
        this.commit_directive(arr);
      }
      if (e == 25) {
        this.handle_zoom(1);
      }
      this.value2 = e;
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      let buffer = [165, 4, 3, 1, 53];
      // let buffer = MMC_Gimbal_ZT30N.zoom((this.zoom = 1));\
      this.commit_directive(buffer);
    },
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value;
      let buffer = MMC_Gimbal_ZT30N.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_image_mode(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_image_mode(value);
      this.commit_directive(buffer);
    },
    handle_change_thermal_mode(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_thermal_mode(value);
      this.commit_directive(buffer);
    },
    handle_change_thermal_zoom(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_thermal_zoom(value);
      this.commit_directive(buffer);
    },
    handle_zoom(mode) {
      let buffer = [165, 4, 3, 1, 53];
      this.commit_directive(buffer);
    },
    // handle_zoom(mode) {
    //   let buffer = MMC_Gimbal_ZT30N.zoom(mode);
    //   this.commit_directive(buffer);
    // },
    handle_zoom_reduce() {
      // let buffer = MMC_Gimbal_ZT30N.zoom(mode);
      let arr = [165, 4, 3, 0, 4];
      this.commit_directive(arr);
    },
    handle_zoom_plus() {
      // let buffer = MMC_Gimbal_ZT30N.zoom(mode);
      let arr = [165, 4, 3, 2, 102];
      this.commit_directive(arr);
    },
    commit_directive(buffer) {
      this.$emit("directive", buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0);
          buffer = [165, 7, 3, 0, 206];
          this.commit_directive(buffer);
          setTimeout(() => {
            // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(1);
            buffer = [165, 6, 3, 3, 219];
            this.commit_directive(buffer);
          }, 50);
          break;
        case "left":
          // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0);
          buffer = [165, 6, 3, 0, 136];
          this.commit_directive(buffer);
          setTimeout(() => {
            // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(-1);
            buffer = [165, 7, 3, 3, 157];
            this.commit_directive(buffer);
          }, 50);
          break;
        case "right":
          // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0);
          buffer = [165, 6, 3, 0, 136];
          this.commit_directive(buffer);
          setTimeout(() => {
            // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(1);
            buffer = [165, 7, 3, 253, 0];
            this.commit_directive(buffer);
          }, 50);
          break;
        case "down":
          // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0);
          buffer = [165, 7, 3, 0, 206];
          this.commit_directive(buffer);
          setTimeout(() => {
            // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(-1);
            buffer = [165, 6, 3, 253, 70];
            this.commit_directive(buffer);
          }, 50);
      }

      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //   // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0);
      //   buffer = [165, 6, 3, 0, 136];
      //   this.commit_directive(buffer);
      //   // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0);
      //   buffer = [165, 7, 3, 0, 206];
      //   this.commit_directive(buffer);
      // }, 500);
    },
    stopfxFn() {
      let buffer = null;
      buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0);
      buffer = [165, 6, 3, 0, 136];
      this.commit_directive(buffer);
      // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0);
      buffer = [165, 7, 3, 0, 206];
      this.commit_directive(buffer);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case "up":
              // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0);
              buffer = [165, 7, 3, 0, 206];
              this.commit_directive(buffer);
              setTimeout(() => {
                // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(1);
                buffer = [165, 6, 3, 3, 219];
                this.commit_directive(buffer);
              }, 50);
              break;
            case "right":
              // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0);
              buffer = [165, 6, 3, 0, 136];
              this.commit_directive(buffer);
              setTimeout(() => {
                // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(-1);
                buffer = [165, 7, 3, 253, 0];
                this.commit_directive(buffer);
              }, 50);
              break;
            case "left":
              // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0);
              buffer = [165, 6, 3, 0, 136];
              this.commit_directive(buffer);
              setTimeout(() => {
                // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(1);
                buffer = [165, 7, 3, 3, 157];
                this.commit_directive(buffer);
              }, 50);
              break;
            case "down":
              // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0);
              buffer = [165, 7, 3, 0, 206];
              this.commit_directive(buffer);
              setTimeout(() => {
                // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(-1);
                buffer = [165, 6, 3, 253, 70];
                this.commit_directive(buffer);
              }, 50);
          }
        },
        onend: () => {
          // buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0);
          buffer = [165, 6, 3, 0, 136];
          this.commit_directive(buffer);
          // buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0);
          buffer = [165, 7, 3, 0, 206];
          this.commit_directive(buffer);
        },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_ZT30N {
  width: 500px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  box-sizing: border-box;
  // padding: 10px 20px;
  position: absolute;
  right: 0px;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;

    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .input-box {
          display: flex;
          align-items: center;
          .mono {
            display: flex;
            flex-direction: column;
            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;
              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }
              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }
            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      flex: 1;

      .title-box {
        .title {
          color: #cbd0eb;
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .el-button {
    background: #004fff;
    color: #000;
    border: none;
  }
}

/deep/.el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}

// 变焦功能
.iconfont {
  color: #004fff;
}
// 变焦功能
/deep/ .el-slider__runway {
  visibility: hidden;
}
.cur {
  cursor: pointer;
}
</style>
